import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { useState, useRef } from 'react';
import axios from "axios";
import { useNavigate, useLoaderData } from "react-router-dom";
import Adress from '../components/Adress';
import Spinner from 'react-bootstrap/Spinner';
import MetaTags from '../components/MetaTags';




export async function LoaderHome({ params }) {
    console.log({params});
    let p = {params: {}};
    if(params.c != undefined)
        p.params['city'] = params.c;
    if(params.s != undefined)
        p.params['street'] = params.s;
    if(params.b != undefined)
        p.params['building'] = params.b;
    let res = axios.get(process.env.REACT_APP_API_URL + '/api/blog_gpvs', p).then((response) => {
        
        return {params: p.params, result: response.data['hydra:member']};
    });
    return res;
}

const defaultOptions = [
    {value: 0, label: 'Львів'},
    {value: 1, label: 'Сокільники'},
    {value: 2, label: 'Дубляни'},
    {value: 3, label: 'Дрогобич'},
    {value: 4, label: 'Стрий'},
    {value: 5, label: 'Червоноград'},
    {value: 6, label: 'Радехів'},
    {value: 7, label: 'Рава-Руська'},
    {value: 8, label: 'Жовква'},
    {value: 9, label: 'Золочів'},
];

const Steps = [
    {
        name: 'Населений пункт',
    },
    {
        name: 'Вулиця'
    },
    {
        name: 'Номер будинку'
    }
];

// const mainData = [];
// Gneral Focus Hook
const UseFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

	return [ htmlElRef,  setFocus ] 
}

const Home = () => {
   
    const data = useLoaderData();
    const navigate = useNavigate();
    // console.log(Steps);
    const [loadData, setLoadData] = useState(false);
    const [stepKey, setStepKey] = useState(0);
    const [step, setStep] = useState(Steps[stepKey].name);
    const [selected, setSelected] = useState('1111');
    const [start, setStart] = useState(true);
    const [mainData, setMainData] = useState([]);
    
    const [inputHide, setInputHide] = useState(false);

    const [input1Ref, setInput1Focus] = UseFocus()
    // console.log(process.env.REACT_APP_API_URL);
    if(data != undefined){
        if(!loadData && data.result.length > 0){
            setLoadData(true);
            let k = Object.keys(data.params).length;
            console.log(k);
            setStepKey(k);
            setStep(Steps[k].name);
            setSelected('');
            let e = [];
            console.log(data)
            if(data.params.city != undefined)
                e.push({value: 1, label: data.params.city})
            if(data.params.street != undefined)
                e.push({value: 1, label: data.params.street})
            if(data.params.building != undefined)
                e.push({value: 1, label: data.params.building})
            
            setMainData(e);
            console.log(mainData);
            
        }
    }

    const loadOptions = (
    
        inputValue: string,
        callback: (options: ColourOption[]) => void
      ) => {
        // inputValue
        inputValue = inputValue.trim();
        if(!start &&  stepKey == 0){
            if(inputValue.length < 3){
                callback(defaultOptions); 
                return;
            }
            let res =  axios.get(process.env.REACT_APP_API_URL + '/api/city_gpvs?city=' + inputValue).then((response) => {

                if(Object.keys(response.data['hydra:member']).length > 0){
                    let newObj = [];
                    let data = response.data['hydra:member'];
                    for(let i=0; i < Object.keys(data).length; i++){
                        let un = true;
                        for(let k = 0;  k < Object.keys(newObj).length; k++){
                            if(newObj[k] != undefined){
                                // console.log(data[i]);
                                if(newObj[k].label == data[i].city){
                                    un = false;
                                }
                            }
                        }
                        if(un){
                            newObj.push({
                                value: data[i].id,
                                label: data[i].city,

                            });
                        }
                    }
                    if(Object.keys(newObj).length > 0){
                        callback(newObj); 
                    }
                }
                
                return response;
            }); 
        }else if(stepKey == 1){
            if(inputValue.length < 3)
                return;
            let res =  axios.get(process.env.REACT_APP_API_URL + '/api/street_gpvs?city=' + mainData[0].label + '&street=' + inputValue).then((response) => {
                if(Object.keys(response.data['hydra:member']).length > 0){
                    let newObj = [];
                    let data = response.data['hydra:member'];
                    for(let i=0; i < Object.keys(data).length; i++){
                        let un = true;
                        for(let k = 0;  k < Object.keys(newObj).length; k++){
                            if(newObj[k] != undefined){
                                // console.log(data[i]);
                                if(newObj[k].label == data[i].street){
                                    un = false;
                                }
                            }
                        }
                        if(un){
                            newObj.push({
                                value: data[i].id,
                                label: data[i].street,

                            });
                        }
                    }
                    if(Object.keys(newObj).length > 0){
                        callback(newObj); 
                    }
                }
                return response;
            });
        }
        else if(stepKey == 2){
            let res =  axios.get(process.env.REACT_APP_API_URL + '/api/buildings_gpvs?city=' + mainData[0].label + '&street=' + mainData[1].label + '&building=' + inputValue).then((response) => {
                if(Object.keys(response.data['hydra:member']).length > 0){
                    let newObj = [];
                    let data = response.data['hydra:member'];
                    for(let i=0; i < Object.keys(data).length; i++){
                        let un = true;
                        for(let k = 0;  k < Object.keys(newObj).length; k++){
                            if(newObj[k] != undefined){
                                // console.log(data[i]);
                                if(newObj[k].label === data[i].building){
                                    un = false;
                                }
                            }
                        }
                        if(un){
                            newObj.push({
                                value: data[i].hash,
                                label: data[i].building,

                            });
                        }
                    }
                    if(Object.keys(newObj).length > 0){
                        callback(newObj); 
                    }
                } 
                return response;
            });
        }
        setStart(false);
      };
    const change = (e) => {
        // console.log(e);
        // console.log(stepKey);
        setInputHide(true);
        if(stepKey != 2){
            let k = stepKey;
            k++;
            setStepKey(k);
            // console.log(stepKey);
            setStep(Steps[k].name)
            setSelected('');
            mainData.push(e);
            setTimeout(
                () => {
                    setInputHide(false);
                    setInput1Focus();
                }, 500
            );

        }else{
            mainData.push(e)
         
            let url = "/" + e.value;
            let myItem = localStorage.getItem('de-svitlo-my-history');
            let json = [];
            let newData = {
                url: url,
                city:  mainData[0].label,
                street: mainData[1].label, 
                build: mainData[2].label,
            };
            if(myItem != null && myItem != 'null'){
                json = JSON.parse(myItem);
                let search = false;
                for(let i = 0; i < Object.keys(json).length; i++){
                    if(json[i].url == url){
                        search = true;
                    }
                }
                if(!search)
                    json.push(newData);
            }else{
                json.push(newData);
            }
            setStepKey(0);
            // console.log(json);
            localStorage.setItem('de-svitlo-my-history', JSON.stringify(json));

            navigate(url);
        }

        
    }
    const myRef = useRef(null);

    const focus = () => {
        // const element = document.getElementById('scrollSmooth');
        // if (element) {
        //     // 👇 Will scroll smoothly to the top of the next section
        //     element.scrollIntoView({ behavior: 'smooth' });
        //   }
        setTimeout(function(){
            myRef.current.scrollIntoView();
        }, 1000);
    }

    return(
        <>
            <MetaTags/>
            <Container>
                <Row>
                    <Col>
                        <Card id="cardGeneral">
                            <Card.Body>
                                <Adress 
                                    city={( (mainData[0] != undefined)? mainData[0].label : '' )} 
                                    street={( (mainData[1] != undefined)? mainData[1].label : '' )} 
                                    building={( (mainData[2] != undefined)? mainData[2].label : '' )} 
                                />
                                {
                                    (!inputHide)?
                                    <>
                                        <h4 className='text-center text-black nameStep'>{step}</h4>
                                        <div className='d-flex justify-content-center mb-5'>
                                            <div className='w-50' style={{minWidth: '200px'}} ref={myRef}>
                                                {(stepKey != 2)? <label>Для пошуку  введіть перших 3 літери</label>: ''}
                                                <AsyncSelect 
                                                    сacheOptions 
                                                    loadOptions={loadOptions} 
                                                    defaultOptions={(stepKey == 0)?defaultOptions:[]}  
                                                    onChange={change}  
                                                    value={selected}
                                                    onFocus={focus}
                                                    ref={input1Ref}
                                                    // placeholder={'Введіть ' + step}
                                                    placeholder='Введіть 3 літери щоб побачити підказку'
                                                />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='d-flex justify-content-center'>
                                            <Spinner animation="grow" variant="primary" />
                                            <Spinner animation="grow" variant="warning" />
                                            <Spinner animation="grow" variant="primary" />
                                            <Spinner animation="grow" variant="warning" />
                                        </div>
                                    </>
                                
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <style>
                {/* {'body {background-image: url(/img/'+(stepKey+1)+'.png);}'} */}
            </style>
        </>
    );
};

export default Home;
