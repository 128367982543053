import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link} from 'react-router-dom';
import * as React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SharerBtn from '../components/sharerBtn';
import Image from 'react-bootstrap/Image'


const pageNav = {
    0: {
        link: "/",
        name: "Головна"
    },
};


const TopMenu = () => {
    // const  IemsMenu = (page) => {
    //     if(pageNav[page].items == undefined ){
    //         return <Link to={pageNav[page].link} className="nav-link">{pageNav[page].name}</Link>;
    //     }else {
    //         return(
    //             <NavDropdown title={pageNav[page].name} id="basic-nav-dropdown">
    //                 {Object.keys(pageNav[page].items).map((sub_page, i) => (
    //                     <Link to={pageNav[page].items[sub_page].link} className="dropdown-item">{pageNav[page].items[sub_page].name}</Link>
    //                 ))}
    //             </NavDropdown>
    //         )
    //     }
    // }
    return (
        <>
            <Container>
                {/* <Navbar bg="light" expand="lg"> */}
                <Row className='bg-white NavBar'>
                    <Col className='d-flex align-items-center justify-content-start'><Navbar.Brand href="/">Головна</Navbar.Brand></Col>
                    <Col className='d-flex align-items-center justify-content-center'><Navbar.Brand href="/"><Image src="/img/logo-de-svitlo.png" className='Logo'/></Navbar.Brand></Col>
                    <Col className='d-flex align-items-center justify-content-end'>
                        <SharerBtn/>
                        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                    </Col>
                </Row>
                    
                    
                    
                   
                {/* </Navbar> */}
                <Row className='m-0'>
                    <Col className='mb-5'>
                        <h1 className="text-center mt-3 text-white">Графік відключення електроенергії у Львівській області</h1>   
                        <p className="text-center text-white">Дізнатись причину діючого вимкнення, графік відключення, групу у графіку для обраної адреси.</p>       
                    </Col>
                </Row>
            </Container>
            
        </>

    );
};


export default TopMenu;
