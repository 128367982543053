import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { FcNoIdea , FcIdea } from "react-icons/fc";




const Action = (props) => {
    const data = props.data;
    const [podiya, setPodiya] = useState({
        start: '',
        end: '',
        type: '',
        prechina: '',
    });
    const [loader, setLoader] = useState(true);

    const dateAction = (dateStart, dateEnd) => {
        let now = new Date();
       
        let date_start = new Date(dateStart);
        let date_end = new Date(dateEnd);
        let start = date_start.getHours() + ":" + ( date_start.getMinutes() < 10 ? '0' + date_start.getMinutes(): date_start.getMinutes());
        let end = date_end.getHours() + ":" + ( date_end.getMinutes() < 10 ? '0' + date_end.getMinutes(): date_end.getMinutes());
        
        if(
            date_start.getDate() === date_end.getDate()
            && (
                (date_end.getHours() == now.getHours() && date_end.getMinutes() <= now.getMinutes) 
                || 
                (date_end.getHours() <= now.getHours() )
            )
        ){
            end = (now.getHours()+1) + ":00 ^";
        }

        return {
            start: start,
            end: end
        }
    }
    useEffect(() => {
        const dataFetch =  () => {
          if(data == undefined){
            return;
          }
          let p = {params: {
            city_name: data.city,
            street_name: data.street,
            buildings: data.building + ',',
          }};
          axios.get(process.env.REACT_APP_API_URL + '/api/actual_podiis', p).then((response) => {
            let data = response.data['hydra:member'];
            if(Object.keys(response.data['hydra:member']).length > 0){
                Object.keys(data).map((key, index) => {
                    let row = data[key];
                    let now = new Date();
                    let DateRow = new Date(row.date_event);
                    if(now.getDate() == DateRow.getDate()){
                        console.log(row);
                        console.log(key);

                        let date = dateAction(row.date_event, row.date_plan_in);
                        // let date = dateAction('2023-01-21T17:00:00+02:00', '2023-01-22T01:00:00+02:00');
                        setPodiya(
                            {
                                start: date.start,
                                end: date.end,
                                type: row.type_off,
                                prechina: row.prychuna,
                            }
                        );
                    }

                });  
                              
            }
            setLoader(false);
        });
          
    };
    dataFetch();
    }, [data]);

    const date = () =>  {
        let date = new Date(Date.now());
        let d = date.getDate();
        let m = date.getMonth();
        let y = date.getFullYear();
        let H = date.getHours();
        let M = date.getMinutes();
        let S = date.getSeconds();
        m++;
        m = (m < 10)? '0' + m: m;
        d = (d < 10)? '0' + d: d;
        H = (H < 10)? '0' + H: H;
        M = (M < 10)? '0' + M: M;
        return `${y}.${m}.${d} ${H}:${M}`;
    }
    const [nowDate, setNowDate] = useState(date);

    return (
        <>
            {
                (typeof podiya == 'object' && podiya.start != '') ?
                    <>
                        <div className='alertPodiya mt-3'>
                        <Alert key="light" variant="danger">

                            <p><FcNoIdea/> Зафіксовано вимкнення:</p>
                            <p>{podiya.type} Від: <strong>{podiya.start}</strong> год.  до:  <strong>{podiya.end}</strong> год.  {(podiya.prechina != '')? 'Причина вимкнення:' + podiya.prechina : ''}</p>
                            <p className='m-0 text-secondary'>станом на {nowDate}</p>
                        </Alert>
                        </div>
                    </>
                : 
                    (!loader)? 
                        <>
                            <div className='alertPodiya mt-3'>
                                <Alert key="light" variant="success">
                                    <h4 className='text-success'>💡 Вимкненнь не зафіксовано</h4>
                                    <p className=' m-0  text-secondary'>станом на {nowDate}</p>
                                </Alert>
                            </div>
                        </>
                    :
                        <>
                            <div className='alertPodiya mt-3'>
                            <Spinner animation="grow" variant="success" />
                            </div>
                        </>
            }
        </>
    );
}


export default Action;