import TopMenu from '../components/TopMenu';
import Footer from '../components/Footer';
import Offline from '../components/Offline';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const NoPage = () => {
    return (
        <>
            <TopMenu/>
            <Container>
                <Row>
                    <Col>
                        <Card id="cardGeneral">
                            <Card.Body>
                                <h1 className='text-center'>404 Сторінку не знайдено!</h1>
                                <h4 className='text-center text-secondary'>Щоб переглянути графік, перейдіть на головну сторінку і виконайте кроки!</h4>
                                <div className='d-flex justify-content-center'>
                                    <Link to="/" className='btn btn-outline-dark'>Головна</Link>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </>
    );
};

export default NoPage;
