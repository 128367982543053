import { Link } from "react-router-dom";
import { BsPencilSquare } from "react-icons/bs";


const Adress = (props) => {
    let city = (props.city != undefined && props.city != '') ? props.city : false;    
    let street = (props.street != undefined && props.street != '') ? props.street : false;
    let building = (props.building != undefined && props.building != '') ? props.building : false;    
    // BsPencilSquare
    return (
        <>
            <p className='text-center address_info'>
                місто: <Link to={(city)? `/`: '#'}><strong>{(city)? city: '......'}</strong><BsPencilSquare/></Link>&ensp;
                вул: <Link to={(street)? `/e/${city}`: '#'}><strong>{(street)? street: '......'}</strong><BsPencilSquare/></Link> &ensp;
                буд: <Link to={(building)? `/e/${city}/${street}`: '#'}><strong>{(building)? building: '......'}</strong><BsPencilSquare/></Link> 
            </p>
        </>
    );
}

export default Adress;