import { FiFacebook } from "react-icons/fi";
import { FaTelegramPlane, FaViber } from "react-icons/fa";
import {BiCopy } from "react-icons/bi";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const SharerBtn = (props) => {
    const id = (props.id != undefined)? props.id: '';
    const urls = {
        f: 'https://www.facebook.com/sharer/sharer.php?u=https://lviv.de-svitlo.com.ua/' + id,
        t: 'tg://msg_url?url=https://lviv.de-svitlo.com.ua/'+ id +'&text=Графік відключень',
        v: 'viber://forward?url=https://lviv.de-svitlo.com.ua/'+ id,
        c: 'https://lviv.de-svitlo.com.ua/'+ id
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          {props}
        </Tooltip>
    );

    return (
        <>
            <ul className="sharerBtn">
                <OverlayTrigger placement="bottom" overlay={renderTooltip('поділитись посиланням у Facebook')}>
                    <li className="facebook"><a href={urls.f} target="_blank" rel="noopener"> <FiFacebook/></a></li>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={renderTooltip('поділитись посиланням у Telegram')}>
                    <li className="telegram"><a href={urls.t} target="_blank" rel="noopener"> <FaTelegramPlane/></a></li>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={renderTooltip('поділитись посиланням у Viber')}>
                    <li className="viber"><a href={urls.v} target="_blank" rel="noopener"> <FaViber/></a></li>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={renderTooltip('копіювати посилання')}>
                    <li className="copy"><a href="#" onClick={() => {navigator.clipboard.writeText(urls.c)}}> <BiCopy/></a></li>
                </OverlayTrigger>
                
            </ul>
        </>

    );
}

export default SharerBtn;