import { useState } from "react";
import { RiWifiOffLine } from "react-icons/ri";


const Offline = () => {
    return (
        <>
            <p id="NotNetwork"><RiWifiOffLine/> Нема з`єднання з мережею!</p>
            <style>
                { "footer{display: none;}"}
            </style>
        </>
    );
}

export default  Offline;