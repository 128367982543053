import { Outlet, Link } from "react-router-dom";
import TopMenu from '../components/TopMenu';
import Footer from '../components/Footer';
import Offline from '../components/Offline';
import { useState } from "react";


const Layout = () => {
    const [online, setOnline] = useState(false);
    if(window.navigator.onLine){
        if(!online)
            setOnline(true);
    }else {
        if(online)
            setOnline(false);
    }
    window.addEventListener('online', () => {
        if(!online)
            setOnline(true);
    });
    window.addEventListener('offline', () => {
        if(online)
            setOnline(false);
    });
    return (

        <>
            <TopMenu/>
            {(!online)? <Offline/>: ''}
            <Outlet />
            <Footer/>
        </>
    )
};

export default Layout;
