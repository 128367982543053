const Group3 = (props) => {
    let date = new Date();
    let h = date.getHours();
    let d = date.getDay() + 1;
    if (props.day == 1)
        return (
            <>
                <table className="table table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="row"><i className="fa fa-clock-o" aria-hidden="true" /> години
                            </th>
                            <th scope="row">3 група</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={(h >= 1 && h < 5)?'active': ''}>
                            <th scope="row">1:00 - 5:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                         <tr className={(h >= 5 && h < 9)?'active': ''}>
                            <th scope="row">5:00 - 9:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr className={(h >= 9 && h < 13)?'active': ''}>
                            <th scope="row">9:00 - 13:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                        <tr className={(h >= 13 && h < 17)?'active': ''}>
                            <th scope="row">13:00 - 17:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                        <tr className={(h >= 17 && h < 21)?'active': ''}>
                            <th scope="row">17:00 - 21:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr className={(h >= 21 && h < 1)?'active': ''}>
                            <th scope="row">21:00 - 01:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    if (props.day == 2)
        return (
            <>
                <table className="table table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="row"><i className="fa fa-clock-o" aria-hidden="true" /> години
                            </th>
                            <th scope="row">3 група</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={(h >= 1 && h < 5)?'active': ''}>
                            <th scope="row">1:00 - 5:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                         <tr className={(h >= 5 && h < 9)?'active': ''}>
                            <th scope="row">5:00 - 9:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                        <tr className={(h >= 9 && h < 13)?'active': ''}>
                            <th scope="row">9:00 - 13:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                        <tr className={(h >= 13 && h < 17)?'active': ''}>
                            <th scope="row">13:00 - 17:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr className={(h >= 17 && h < 21)?'active': ''}>
                            <th scope="row">17:00 - 21:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                        <tr className={(h >= 21 && h < 1)?'active': ''}>
                            <th scope="row">21:00 - 01:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );

    if (props.day == 3)
        return (
            <>
                <table className="table table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="row"><i className="fa fa-clock-o" aria-hidden="true" /> години
                            </th>
                            <th scope="row">3 група</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={(h >= 1 && h < 5)?'active': ''}>
                            <th scope="row">1:00 - 5:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                         <tr className={(h >= 5 && h < 9)?'active': ''}>
                            <th scope="row">5:00 - 9:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                        <tr className={(h >= 9 && h < 13)?'active': ''}>
                            <th scope="row">9:00 - 13:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr className={(h >= 13 && h < 17)?'active': ''}>
                            <th scope="row">13:00 - 17:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                        <tr className={(h >= 17 && h < 21)?'active': ''}>
                            <th scope="row">17:00 - 21:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                        <tr className={(h >= 21 && h < 1)?'active': ''}>
                            <th scope="row">21:00 - 01:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    if (props.day == 4)
        return (
            <>
                <table className="table table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="row"><i className="fa fa-clock-o" aria-hidden="true" /> години
                            </th>
                            <th scope="row">3 група</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={(h >= 1 && h < 5)?'active': ''}>
                            <th scope="row">1:00 - 5:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                         <tr className={(h >= 5 && h < 9)?'active': ''}>
                            <th scope="row">5:00 - 9:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr className={(h >= 9 && h < 13)?'active': ''}>
                            <th scope="row">9:00 - 13:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                        <tr className={(h >= 13 && h < 17)?'active': ''}>
                            <th scope="row">13:00 - 17:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                        <tr className={(h >= 17 && h < 21)?'active': ''}>
                            <th scope="row">17:00 - 21:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr className={(h >= 21 && h < 1)?'active': ''}>
                            <th scope="row">21:00 - 01:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    if (props.day == 5)
        return (
            <>
                <table className="table table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="row"><i className="fa fa-clock-o" aria-hidden="true" /> години
                            </th>
                            <th scope="row">3 група</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={(h >= 1 && h < 5)?'active': ''}>
                            <th scope="row">1:00 - 5:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                         <tr className={(h >= 5 && h < 9)?'active': ''}>
                            <th scope="row">5:00 - 9:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                        <tr className={(h >= 9 && h < 13)?'active': ''}>
                            <th scope="row">9:00 - 13:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                        <tr className={(h >= 13 && h < 17)?'active': ''}>
                            <th scope="row">13:00 - 17:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr className={(h >= 17 && h < 21)?'active': ''}>
                            <th scope="row">17:00 - 21:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                        <tr className={(h >= 21 && h < 1)?'active': ''}>
                            <th scope="row">21:00 - 01:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    if (props.day == 6)
        return (
            <>
                <table className="table table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="row"><i className="fa fa-clock-o" aria-hidden="true" /> години
                            </th>
                            <th scope="row">3 група</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={(h >= 1 && h < 5)?'active': ''}>
                            <th scope="row">1:00 - 5:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                         <tr className={(h >= 5 && h < 9)?'active': ''}>
                            <th scope="row">5:00 - 9:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                        <tr className={(h >= 9 && h < 13)?'active': ''}>
                            <th scope="row">9:00 - 13:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr className={(h >= 13 && h < 17)?'active': ''}>
                            <th scope="row">13:00 - 17:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                        <tr className={(h >= 17 && h < 21)?'active': ''}>
                            <th scope="row">17:00 - 21:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                        <tr className={(h >= 21 && h < 1)?'active': ''}>
                            <th scope="row">21:00 - 01:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    if (props.day == 7)
        return (
            <>
                <table className="table table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="row"><i className="fa fa-clock-o" aria-hidden="true" /> години
                            </th>
                            <th scope="row">3 група</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={(h >= 1 && h < 5)?'active': ''}>
                            <th scope="row">1:00 - 5:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                         <tr className={(h >= 5 && h < 9)?'active': ''}>
                            <th scope="row">5:00 - 9:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr className={(h >= 9 && h < 13)?'active': ''}>
                            <th scope="row">9:00 - 13:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                        <tr className={(h >= 13 && h < 17)?'active': ''}>
                            <th scope="row">13:00 - 17:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                        <tr className={(h >= 17 && h < 21)?'active': ''}>
                            <th scope="row">17:00 - 21:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr className={(h >= 21 && h < 1)?'active': ''}>
                            <th scope="row">21:00 - 01:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    if (props.day == 'all')
        return (
            <>
                <table className="table table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="row" rowSpan={1}>Дні/Години
                            </th>
                            <th colSpan={1}>Понеділок</th>
                            <th colSpan={1}>Вівторок</th>
                            <th colSpan={1}>Середа</th>
                            <th colSpan={1}>Четвер</th>
                            <th colSpan={1}>П’ятниця</th>
                            <th colSpan={1}>Субота</th>
                            <th colSpan={1}>Неділя</th>
                        </tr>
                        <tr>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1:00 - 5:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                        <tr>
                            <th scope="row">5:00 - 9:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr>
                            <th scope="row">9:00 - 13:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                        <tr>
                            <th scope="row">13:00 - 17:00</th>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                        </tr>
                        <tr>
                            <th scope="row">17:00 - 21:00</th>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                        </tr>
                        <tr>
                            <th scope="row">21:00 - 01:00</th>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                            <td className="bg-white">🤨 Можливе відключення</td>
                            <td className="bg-warning">😭 Немає електроенергії</td>
                            <td className="bg-success">😀 Є електроенергія</td>
                        </tr>
                    </tbody>
                </table>

                <style>
                    {(h >= 1 && h < 5)?'.all_date tbody tr:nth-child(1) td:nth-child('+d+'){border: 5px solid #5eb5f7; font-size: 18px; font-weight: bold; color: #000000;}': ''}
                    {(h >= 5 && h < 9)?'.all_date tbody tr:nth-child(2) td:nth-child('+d+'){border: 5px solid #5eb5f7; font-size: 18px; font-weight: bold; color: #000000;}': ''}
                    {(h >= 9 && h < 13)?'.all_date tbody tr:nth-child(3) td:nth-child('+d+'){border: 5px solid #5eb5f7; font-size: 18px; font-weight: bold; color: #000000;}': ''}
                    {(h >= 13 && h < 17)?".all_date tbody tr:nth-child(4) td:nth-child("+d+"){border: 5px solid #5eb5f7; font-size: 18px; font-weight: bold; color: #000000;}": ''}
                    {(h >= 17 && h < 21)?'.all_date tbody tr:nth-child(5) td:nth-child('+d+'){border: 5px solid #5eb5f7; font-size: 18px; font-weight: bold; color: #000000;}': ''}
                    {(h >= 21 && h < 1)?'.all_date tbody tr:nth-child(6) td:nth-child('+d+'){border: 5px solid #5eb5f7; font-size: 18px; font-weight: bold; color: #000000;}': ''}
                </style>
            </>
        );

}

export default Group3;