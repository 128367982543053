import Button from "react-bootstrap/esm/Button";
import { FaTelegramPlane } from "react-icons/fa";
import axios from "axios";
import { useState } from "react";



const md5 = require("blueimp-md5");

function date() {
    let date = new Date(Date.now());
    let d = date.getDate();
    let m = date.getMonth();
    let y = date.getFullYear();
    let H = date.getHours();
    let M = date.getMinutes();
    let S = date.getSeconds();
    m++;
    m = (m < 10)? '0' + m: m;
    d = (d < 10)? '0' + d: d;
    H = (H < 10)? '0' + H: H;
    M = (M < 10)? '0' + M: M;
    S = (S < 10)? '0' + S: S;
    return `${y}${m}${d}${H}${M}${S}`;
}


const BtnBot = (props) => {
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [addAdress, setAddAdress] = useState(false);
    const [showData, setShowData] = useState({
        rez: false,
        txt: '',
        color: '',
        btn: true,

    });
    
    let BotHash = localStorage.getItem('de-svitlo-bot-hash');

    if((BotHash == null || BotHash == undefined || BotHash == '') && !addAdress){

        // var hash = md5(props.data.hash + '-' + date() + '-asdDESVITLO');
        const telegram_url = 'https://t.me/de_svitlo_ua_bot?start=' + props.data.hash + '-' + date();

        return <a target='_blanck' href={telegram_url} className="btn text-white btnTelegram"> <FaTelegramPlane /> Підключити сповіщення</a>
    }else{
       
        const botClick = () => {
            setAddAdress( true );
            let dateNow = date();
            let myHash = md5(`${props.data.hash}-${BotHash}-${dateNow}`);

            let request = { 
                data: {
                    method: 'add-address-by-hash',
                    hash: props.data.hash,
                    ext_hash: BotHash,
                },
                date: dateNow,
                hash: myHash

            };
            axios.post(`${process.env.REACT_APP_API_URL}/bot`, request).then(res => {
                setBtnDisabled(true);   
                if(res.data.rez){
                    setShowData({
                        rez: true,
                        txt: '😎 Адресу успішно додано до вашого бота',
                        color: 'green',
                        btn: false,

                    });
                    // localStorage.setItem('de-svitlo-bot-hash', '');
                }else{
                    setShowData({
                        rez: true,
                        txt: '😒 Помилка додавання адреси зайдіть у бот і спробуйте ще раз!',
                        color: 'red',
                        btn: true,
                    })
                }
            });
        }
        return (
            <>
                {(showData.btn)? <Button onClick={botClick} className="btn text-white btnTelegram" disabled={btnDisabled}> <FaTelegramPlane /> Додати адресу до бота</Button>: ''} 
                {(showData.rez)? <p style={{color: showData.color}}>{showData.txt}</p>: ''}
            </>
        );
    }

    
}

export default BtnBot;