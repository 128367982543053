import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, RouterProvider, createBrowserRouter} from "react-router-dom";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";


import Layout from "./pages/Layout";
import Home, {LoaderHome} from "./pages/Home";
import GetHash, {LoaderGetHash} from "./pages/GetHash";
import NoPage from "./pages/NoPage";
import Content, {LoaderContent} from "./pages/Content";


const router = createBrowserRouter([
  {
      path: "/",
      element: <Layout />,
      errorElement: <NoPage />,
      // loader: rootLoader,
      // action: rootAction,
      children: [
          { index: true, element: <Home /> },
          // { path: "contact", element: <Contact /> },
          // { path: "form-page", element: <FormPage /> },
          // {
          //     path: "news",
          //     element: <News/>,
          //     loader: newsLoader,
          // },
          {
              path: "/:id",
              element: <Content/>,
              loader: LoaderContent,
          },
          {
              path: "e/:c",
              element: <Home/>,
              loader: LoaderHome,
          },
          {
              path: "e/:c/:s",
              element: <Home/>,
              loader: LoaderHome,
          },
          {
              path: "e/:c/:s/:b",
              element: <Home/>,
              loader: LoaderHome,
          },
          {
              path: "bot/:hash",
              element: <GetHash/>,
              loader: LoaderGetHash,
          },
      ],
  },
]);
function someRequest() { //Simulates a request; makes a "promise" that'll run for 2.5 seconds
  return new Promise(resolve => setTimeout(() => resolve(), 2500));
} 

function App() {
  React.useEffect(() => {
    AOS.init();
  }, []);

  // const [isLoading, setLoading] = useState(true);
  // useEffect(() => {
  //   someRequest().then(() => {
  //     const loaderElement = document.querySelector(".loader-container");
  //     if (loaderElement) {
  //       loaderElement.remove();
  //       setLoading(!isLoading);
  //     }
  //   });
  // });

  // if (isLoading) {
  //   return null;
  // }

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
