import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useLoaderData, useSearchParams } from 'react-router-dom';
import SharerBtn from '../components/sharerBtn';
import CalendarTabs from '../components/CalendarTabs';
import Adress from '../components/Adress';
import BtnBot from '../components/BtnBot';
import Action from '../components/Action';
import Alert from 'react-bootstrap/Alert';
import MetaTags from '../components/MetaTags';




export async function LoaderContent({ params }) {
    let res = axios.get(process.env.REACT_APP_API_URL + '/api/blog_gpvs?hash=' + params.id).then((response) => {
        return response.data['hydra:member'][0];
    });
    return res;
}



const Content = () => {

    const myRef = useRef(null);

    const data = useLoaderData();
    const [city, setCity] = useState(data.city);
    const [street, setStreet] = useState(data.street);
    const [building, setBuilding] = useState(data.building);
    const [group, setGroup] = useState(data.group_type.substring(0, 1));
   
    setTimeout(function(){
        if(window.location.href.indexOf('#grafick') > 0)
            myRef.current.scrollIntoView();
    }, 1100);
    return (
        <>
            <MetaTags city={city} street={street} building={building} hash={data.hash} />
            <Container>
                <Row>
                    <Col>
                        <Card id="cardGeneral" className="content">
                            <Card.Body>
                                <div className='lihtarik'>
                                    <div>
                                        <img src="/img/8-1.png"/>
                                    </div>
                                </div>
                                {/* <h2 className='text-center' data-aos="fade-right" data-aos-duration="1200">Ваша група <span className="badge badge-info spanGroupNumber">{group}.{group_sub  }</span></h2> */}
                                <h2 id="grafick" className='text-center' data-aos="fade-right" data-aos-duration="1200">Ваша група <span className="badge badge-info spanGroupNumber">{(group != 0)? group : 'не визначено'}</span></h2>
                                {
                                    (group != '0')?
                                        <>
                                            <div className='text-center'>
                                                <Adress 
                                                    city={city} 
                                                    street={street} 
                                                    building={building} 
                                                />
                                                <BtnBot data={data}/>
                                                <Action data={data}/>                                    
                                                <div className="box-sharerBtn" ref={myRef}>Поділитися:<SharerBtn id={data.id}/></div>
                                            </div>
                                            {/* <p className="h4">Графік погодинного відключення  <span className="badge badge-info spanGroupNumber">{group}.{group_sub} група</span></p> */}
                                            <p className="h4 mt-3">Графік погодинного відключення  <span className="badge badge-info spanGroupNumber">{group} група</span></p>
                                            <div className='bg-white p-3'>
                                            <CalendarTabs group={group} />
                                            </div>
                                        </>
                                    :
                                        <>
                                            <div className='text-center'>
                                                <Adress 
                                                        city={city} 
                                                        street={street} 
                                                        building={building} 
                                                    />
                                                    <BtnBot data={data}/>
                                            </div>
                                                
                                            <div className='alertPodiya mt-3'>
                                                <Alert key="light" variant="light">
                                                    <p>Дана адреса не прив'язана до жодної з груп аварійних вимкнень, якщо Ви вважаєте це помилкою, то можете звернутись в кол-центр Вашого оператора системи розподілу електроенергії</p>
                                                </Alert>
                                            </div>
                                        </>
                                }
                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>

    );
};

export default Content;
