import * as React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { GrFormView } from "react-icons/gr";
import { BsFillTrashFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';


const MyLocalStorage = JSON.parse( localStorage.getItem('de-svitlo-my-history') );


const  Footer = () =>{
    const [myItem, setMyItem] = useState(MyLocalStorage);
    
    const trashHistory = () => {
        localStorage.setItem('de-svitlo-my-history', null);
        setMyItem(localStorage.getItem('de-svitlo-my-history'))
    }
        return (
            <>
                <Container>
                    <footer>
                        <Card>
                            <Card.Body>
                               <Row>
                                    <Col className="HistoryHover">
                                        <h5 className='text-center'><GrFormView/> Історія переглядів:</h5>
                                        <Button variant="light" onClick={trashHistory}><BsFillTrashFill/>Очистити</Button>
                                    </Col>
                               </Row>
                                    
                                
                                    {
                                        (myItem != null && typeof myItem == 'object' && Object.keys(myItem).length > 0) ? 
                                        <p>{
                                                Object.keys(myItem).map((k) => {
                                                    return <span className='ml-3' key={k}><a href={myItem[k].url}>{'м.' + myItem[k].city + ' вул. ' + myItem[k].street + ' буд. ' + myItem[k].build}</a> |</span>
                                        
                                                })
                                            }
                                        </p>
                                        : 
                                        <p className='text-center'> Переглядів не знайдено! </p>
                                    }
                                <hr/>
                                <p className='mt-5'>Чи правильно казати <b>де світло</b> мабуть ні, але ця фраза і їй подібні, нема світла, графік відключень світла у Львові, графік вимкнень світла, міцно засіла в свідомості суспільства, наші друзі філологи хейтять нас за нашу назву, тому просимо вас говорити правильно: немає електроенергії, графік відключень електроенергії у Львові, графік вимкнень електроенергії ... </p>
                            </Card.Body>
                        </Card>
                    </footer>
                </Container>
            </>

        );
    // else
    //     return (
    //         <></>
    //     );
};

export default Footer;
