import { useState } from "react";
import {Helmet, HelmetProvider} from 'react-helmet-async';


const MetaTags = (props) => {
    const [city, setCity] = useState((props.city != undefined)? props.city: 'Львів');
    const [street, setStreet] = useState((props.street != undefined)? props.city: '');
    const [building, setBuilding] = useState((props.building != undefined)? props.city: '');
    const [hash, setHash] = useState((props.hash != undefined)? props.hash: '');
    return(
        <HelmetProvider>
            <Helmet>
                <title>{`Де світло? ${street} ${building} ${city}`} | Графік відключень Львів | Графік відключень Львівська область | Львів світло | Нема світла</title>
                <meta
                    name="description"
                    content={`${street} ${building} ${city}` + " Графік відключень "+city+" | Графік відключень Львівська область | Групи відключень | "+city+" світло | Нема світла"}
                />
                <meta itemprop="name" content={`Де світло?  ${city} графік відключень` }/>
                <meta itemprop="image" content="https://lviv.de-svitlo.com.ua/mstile-150x150.png"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content={`${street} ${building} ${city}` +" Графік відключень Львів | Графік відключень Львівська область | Львів світло | Нема світла" }/>
                <meta property="og:description" content={`${street} ${building} ${city}` + " Графік відключень, сповіщення на телеграм бот"}/>
                <meta property="og:image" content="https://lviv.de-svitlo.com.ua/mstile-150x150.png"/>
                <meta property="og:url" content={`https://lviv.de-svitlo.com.ua/` + hash}/>
                <meta property="og:site_name" content="Де світло?"/>
            </Helmet>
        </HelmetProvider>
    )
}

export default MetaTags;