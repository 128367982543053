import { useNavigate, useLoaderData } from "react-router-dom";


export async function LoaderGetHash({ params }){
    localStorage.setItem('de-svitlo-bot-hash', params.hash);
    return true;
}


const GetHash = () => {
    const data = useLoaderData();
    // const navigate = useNavigate();
    window.location.replace('/');

}

export default GetHash;