import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Group1 from './group1';
import Group2 from './group2';
import Group3 from './group3';



const CalendarTabs = (props) =>{
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    var d = new Date(Date.now());
    var dayName = days[d.getDay()];

    let group = props.group;

    return (
        <Tabs
        defaultActiveKey={dayName}
        id="justify-tab-example"
        className="mb-3 bg"
        justify
    >
        <Tab eventKey="Monday" title="Понеділок">
            {(group == 1) ? <Group1 day="1" /> : ''}
            {(group == 2) ? <Group2 day="1" /> : ''}
            {(group == 3) ? <Group3 day="1" /> : ''}

        </Tab>
        <Tab eventKey="Tuesday" title="Вівторок">
            {(group == 1) ? <Group1 day="2" /> : ''}
            {(group == 2) ? <Group2 day="2" /> : ''}
            {(group == 3) ? <Group3 day="2" /> : ''}

        </Tab>
        <Tab eventKey="Wednesday" title="Середа">
            {(group == 1) ? <Group1 day="3" /> : ''}
            {(group == 2) ? <Group2 day="3" /> : ''}
            {(group == 3) ? <Group3 day="3" /> : ''}

        </Tab>
        <Tab eventKey="Thursday" title="Четвер">
            {(group == 1) ? <Group1 day="4" /> : ''}
            {(group == 2) ? <Group2 day="4" /> : ''}
            {(group == 3) ? <Group3 day="4" /> : ''}

        </Tab>
        <Tab eventKey="Friday" title="П’ятниця">
            {(group == 1) ? <Group1 day="5" /> : ''}
            {(group == 2) ? <Group2 day="5" /> : ''}
            {(group == 3) ? <Group3 day="5" /> : ''}

        </Tab>
        <Tab eventKey="Saturday" title="Субота">
            {(group == 1) ? <Group1 day="6" /> : ''}
            {(group == 2) ? <Group2 day="6" /> : ''}
            {(group == 3) ? <Group3 day="6" /> : ''}

        </Tab>
        <Tab eventKey="Sunday" title="Неділя">
            {(group == 1) ? <Group1 day="7" /> : ''}
            {(group == 2) ? <Group2 day="7" /> : ''}
            {(group == 3) ? <Group3 day="7" /> : ''}

        </Tab>
        <Tab eventKey="Week" title="Весь тиждень">
            {(group == 1) ? <Group1 day="all" /> : ''}
            {(group == 2) ? <Group2 day="all" /> : ''}
            {(group == 3) ? <Group3 day="all" /> : ''}

        </Tab>
    </Tabs>
    );
}

export default CalendarTabs;